import { Helmet } from "react-helmet-async";

const Meta = ({
  title = "PATBİS Uyumlu Depo Yönetim Yazılımı",
  description = "PATBİS uyumlu maden ocağı yazılımı ile güvenli malzeme yönetimi, depo takibi, patlayıcı madde envanter yönetimi, ve süreç optimizasyonu sağlayın.",
  keywords = "PATBİS, maden ocağı, patlayıcı madde yönetimi, depo yazılımı, malzeme yönetimi, envanter takip, maden güvenliği, risk yönetimi, PATBİS uyumlu yazılım, patlayıcı sevkiyatı, malzeme takibi, PATBİS sistemi, güvenli maden yönetimi, patlayıcı envanteri, maden ocağı yazılımı, patlayıcı izleme sistemi, PATBİS entegrasyonu, maden otomasyonu, PATBİS uyumlu depo, maden operasyonları, güvenli sevkiyat, lojistik yönetimi",
  ogTitle = "PATBİS Uyumlu Depo Yönetim Yazılımı",
  ogDescription = "PATBİS uyumlu maden ocağı yazılımı ile güvenli malzeme yönetimi, depo takibi ve süreç optimizasyonu sağlayın.",
  ogImage = "https://dynoblastizmir.com.tr/static/media/dynablast_logo_small_yesil.d421e483c88c317c7d79.png",
  ogUrl = "https://www.dynoblastizmir.com.tr",
  twitterTitle = "PATBİS Uyumlu Depo Yönetim Yazılımı",
  twitterDescription = "PATBİS uyumlu maden ocağı yazılımı ile güvenli malzeme yönetimi, depo takibi ve süreç optimizasyonu sağlayın.",
  twitterImage = "https://dynoblastizmir.com.tr/static/media/dynablast_logo_small_yesil.d421e483c88c317c7d79.png",
  twitterHandle = "@teirabilisim"
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={twitterTitle} />
      <meta name="twitter:description" content={twitterDescription} />
      <meta name="twitter:image" content={twitterImage} />
      <meta name="twitter:site" content={twitterHandle} />
    </Helmet>
  );
};

export default Meta;
