import React from 'react';

const MadenReferans = () => {
  const referanslar = [
    { isim: 'Alser Madencilik', url: 'https://alsermaden.com/' },
    { isim: 'Yeni Anadolu Madencilik A.Ş', url: 'http://yenianadolumadvetek.com/' },
    { isim: 'Ant İnşaat Maden Sanayi A.Ş.', url: 'https://www.antinsaat.com/tr/AntMaden' },
    { isim: 'Aydın Linyit Madencilik A.Ş.', url: 'http://www.aydinlinyit.com.tr/' },
    { isim: 'Yön Çinkur Madencilik', url: 'https://cinkur.dynoblastizmir.com' },
    { isim: 'Defaş Madencilik', url: 'https://defasmadencilik.com/' },
    { isim: 'Türk Maadin Şirketi A.Ş.', url: 'http://www.turkmaadin.com/' },
    { isim: 'Kömür İşletmeleri A.Ş.', url: 'http://www.kiaskomur.com.tr/' },
    { isim: 'Montan Madencilik Türk A.Ş.', url: 'https://montan.dynoblastizmir.com' },
    { isim: 'Plt Madencilik', url: 'http://www.serpergrup.com.tr/grup-firmalari-plt.html' },
    { isim: 'Seba Madencilik', url: 'https://sebamaden.dynoblastizmir.com' },
    { isim: 'Tavaş Madencilik İnşaat', url: 'https://tavas.dynoblastizmir.com' },
    { isim: 'Üçel Madencilik', url: 'http://www.senbas.com.tr/?page_id=47' },
    { isim: 'Elmacı Madencilik A.Ş.', url: 'http://www.elmaci.com.tr/' },
    { isim: 'Fernas İnşaat A.Ş.', url: 'https://www.fernas.com.tr/project_category/dekanpaj-ve-madencilik/' },
    { isim: 'Kar Madencilik', url: 'https://karmaden.com/tr/' },
    { isim: 'Ölmez Madencilik', url: 'https://www.olmezmadencilik.com/' },
  ];

  return (
    <div className='container py-5'>
      <h2 className="text-center mb-4">Maden İşletmeleri Referanslarımız</h2>
      <div className='row'>
        {referanslar.map((ref, index) => (
          <div key={index} className='col-md-6 col-lg-4 mb-4'>
            <div className='p-3 border bg-light'>
              <h1 className='font-16'><strong>{ref.isim}</strong></h1>
              <a href={ref.url} target='_blank' rel='noopener noreferrer'>İşletmenin Web Sitesi</a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MadenReferans;
