import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import App from "./App";
import NotFound from "./components/NotFound";
import SiteMapPage from "./components/SiteMapPage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import MadenReferans from "./components/MadenReferans";
import KVKKPage from "./components/KVKKPage";
import MdmPage from "./components/MDMPage";

export const webRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="madenref" element={<MadenReferans />} />
      <Route path="sitemap.xml" element={<SiteMapPage />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />;
      <Route path="terms-of-service" element={<TermsOfService />} />;
      <Route path="kvkk" element={<KVKKPage />} />;
      <Route path="mdm" element={<MdmPage />} />;
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);
