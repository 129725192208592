import React from "react";

const TermsOfService = () => {
  return (
    <div className="container my-5">
      <div className="card shadow-lg">
        <div className="card-body">
          <h1 className="card-title text-center mb-4">Hizmet Şartları</h1>
          <p>
            Bu hizmet şartları, <strong>https://www.dynoblastizmir.com.tr</strong> web
            sitesinin kullanımını düzenlemektedir. Web sitemizi kullanarak,
            aşağıda belirtilen şartları kabul etmiş sayılırsınız.
          </p>

          <h2 className="mt-4">Kullanım Şartları</h2>
          <p>Web sitemizi kullanırken aşağıdaki kurallara uymayı kabul ediyorsunuz:</p>
          <ul className="list-group list-group-flush mb-4">
            <li className="list-group-item">
              Web sitemizdeki içerikleri sadece kişisel ve ticari olmayan amaçlarla kullanabilirsiniz.
            </li>
            <li className="list-group-item">
              İzinsiz kopyalama, çoğaltma, değiştirme veya ticari amaçlarla kullanma yasaktır.
            </li>
            <li className="list-group-item">
              Web sitemize zarar verebilecek herhangi bir yasa dışı veya kötü niyetli eylemde bulunmayın.
            </li>
          </ul>

          <h2 className="mt-4">Hesap Güvenliği</h2>
          <p>
            Eğer bir kullanıcı hesabınız varsa, hesabınızın gizliliğini ve güvenliğini korumak sizin
            sorumluluğunuzdadır. Hesabınızla yapılan tüm işlemlerden siz sorumlu olursunuz.
          </p>

          <h2 className="mt-4">Fikri Mülkiyet Hakları</h2>
          <p>
            Web sitemizde yer alan tüm içerikler (yazılar, resimler, grafikler vb.)
            telif haklarıyla korunmaktadır. Bu içerikler, site sahibinin izni olmadan
            ticari amaçla kullanılamaz.
          </p>

          <h2 className="mt-4">Üçüncü Taraf Linkler</h2>
          <p>
            Web sitemizde, üçüncü taraf web sitelerine yönlendiren linkler bulunabilir. Bu
            sitelerin içerikleri ve gizlilik politikaları üzerinde kontrolümüz yoktur ve bu
            sitelerin kullanımından doğabilecek sonuçlardan sorumlu değiliz.
          </p>

          <h2 className="mt-4">Kullanıcı İçerikleri</h2>
          <p>
            Web sitemize yüklenen kullanıcı içerikleri, site sahibinin izni olmadan paylaşılamaz
            veya kullanılmaz. Web sitesine içerik yükleyen kullanıcılar, bu içeriklerin yasal olduğunu
            garanti eder.
          </p>

          <h2 className="mt-4">Yasal Sorumluluk</h2>
          <p>
            Web sitemizin kullanımıyla ilgili olarak oluşabilecek doğrudan veya dolaylı zararlardan
            sorumlu değiliz.
          </p>

          <h2 className="mt-4">Şartların Güncellenmesi</h2>
          <p>
            Hizmet şartları zaman zaman değiştirilebilir. Yapılacak değişiklikler bu sayfada
            yayınlanacaktır ve bu sayfayı düzenli olarak kontrol etmek sizin sorumluluğunuzdadır.
          </p>

          <h2 className="mt-4">İletişim</h2>
          <p>
            Hizmet şartlarıyla ilgili sorularınız için bizimle iletişime geçebilirsiniz:
            <br />
            <strong>E-posta:</strong> admin@dynoblastizmir.com.tr
          </p>

          <p className="text-muted text-end">Son Güncelleme: 21 Eylül 2024</p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
