import React from "react";

const KVKKPage = () => {
  return (
    <div className="container my-5">
      <div className="card shadow-lg">
        <div className="card-body">
          <h1 className="card-title text-center mb-4">Kişisel Verilerin Korunması Kanunu (KVKK)</h1>
          <p>
            İşbu Kişisel Verilerin Korunması Kanunu Aydınlatma Metni, 
            <strong> https://www.dynoblastizmir.com.tr </strong> sitesi üzerinden toplanan kişisel verilerin 
            hangi amaçlarla ve nasıl işleneceğini, bu verilerin kimlerle paylaşılacağını ve kişisel verilerinizin 
            korunmasına dair haklarınızı açıklamaktadır.
          </p>

          <h2 className="mt-4">Toplanan Kişisel Veriler</h2>
          <p>
            Web sitemizi kullandığınızda, aşağıdaki kişisel verilerinizi toplayabiliriz:
          </p>
          <ul className="list-group list-group-flush mb-4">
            <li className="list-group-item">Ad, soyad, e-posta adresi ve telefon numarası gibi kimlik bilgileri.</li>
            <li className="list-group-item">IP adresi, tarayıcı bilgileri ve çerezler aracılığıyla elde edilen veriler.</li>
            <li className="list-group-item">Web sitemizdeki kullanım alışkanlıklarınız ve gezinti geçmişiniz.</li>
          </ul>

          <h2 className="mt-4">Kişisel Verilerin İşlenme Amaçları</h2>
          <p>
            Toplanan kişisel verileriniz, aşağıdaki amaçlarla işlenmektedir:
          </p>
          <ul className="list-group list-group-flush mb-4">
            <li className="list-group-item">Web sitemizin size daha iyi hizmet verebilmesi için kullanılır.</li>
            <li className="list-group-item">Kullanıcı deneyiminizi geliştirmek ve kişiselleştirilmiş içerikler sunmak.</li>
            <li className="list-group-item">Yasal yükümlülüklerin yerine getirilmesi.</li>
          </ul>

          <h2 className="mt-4">Kişisel Verilerin Paylaşımı</h2>
          <p>
            Kişisel verileriniz, üçüncü taraflarla yalnızca aşağıdaki durumlarda paylaşılabilir:
          </p>
          <ul className="list-group list-group-flush mb-4">
            <li className="list-group-item">Yasal yükümlülüklerin yerine getirilmesi için resmi mercilerle paylaşılması gerektiğinde.</li>
            <li className="list-group-item">Hizmet sağlayıcılar veya iş ortakları ile, yalnızca gerekli olması halinde.</li>
          </ul>

          <h2 className="mt-4">Kişisel Verilerin Saklanma Süresi</h2>
          <p>
            Kişisel verileriniz, işlendikleri amaç için gerekli olan süre boyunca saklanacak ve 
            ilgili yasal mevzuat tarafından belirlenen süreler boyunca muhafaza edilecektir.
          </p>

          <h2 className="mt-4">Kişisel Verilerinize Erişim ve Haklarınız</h2>
          <p>
            Kişisel verilerinizle ilgili olarak aşağıdaki haklara sahipsiniz:
          </p>
          <ul className="list-group list-group-flush mb-4">
            <li className="list-group-item">Kişisel verilerinizin işlenip işlenmediğini öğrenme.</li>
            <li className="list-group-item">İşlenen kişisel verileriniz hakkında bilgi talep etme.</li>
            <li className="list-group-item">Kişisel verilerinizin düzeltilmesini isteme.</li>
            <li className="list-group-item">Kişisel verilerinizin silinmesini veya yok edilmesini talep etme.</li>
            <li className="list-group-item">Kişisel verilerinizin işlenmesine itiraz etme.</li>
          </ul>

          <h2 className="mt-4">Çerezler ve Takip Teknolojileri</h2>
          <p>
            Web sitemizde, tarayıcınızda çerezler depolanabilir. Bu çerezler, sitenin kullanımını kolaylaştırmak ve kullanıcı deneyimini iyileştirmek için kullanılır. Çerezleri tarayıcı ayarlarınızdan kapatabilir veya yönetebilirsiniz.
          </p>

          <h2 className="mt-4">Gizlilik ve Güvenlik</h2>
          <p>
            Kişisel verilerinizin gizliliği ve güvenliği bizim için önemlidir. Verilerinizi korumak için gerekli teknik ve idari önlemler alınmaktadır. Ancak internet üzerinden gerçekleştirilen veri aktarımının tamamen güvenli olamayacağını hatırlatmak isteriz.
          </p>

          <h2 className="mt-4">Değişiklikler</h2>
          <p>
            Bu Kişisel Verilerin Korunması Kanunu Aydınlatma Metni zaman zaman güncellenebilir. Herhangi bir değişiklik yapıldığında, güncellenmiş metin bu sayfa üzerinden yayınlanacaktır. Güncellemelerden haberdar olmak için bu sayfayı düzenli olarak kontrol etmeniz önerilir.
          </p>

          <h2 className="mt-4">İletişim</h2>
          <p>
            Kişisel verilerinizin işlenmesiyle ilgili sorularınız veya talepleriniz varsa, bizimle aşağıdaki iletişim adreslerinden ulaşabilirsiniz:
            <br />
            <strong>E-posta:</strong> admin@dynoblastizmir.com.tr
          </p>

          <p className="text-muted text-end">Son Güncelleme: 21 Eylül 2024</p>
        </div>
      </div>
    </div>
  );
};

export default KVKKPage;
