import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container my-5">
      <div className="card shadow-sm">
        <div className="card-body">
          <h1 className="text-center mb-4">Gizlilik Politikası</h1>
          <p className="lead">
            Bu gizlilik politikası, <strong>https://www.dynoblastizmir.com.tr</strong> üzerinde
            toplanan bilgiler ve bu bilgilerin nasıl kullanıldığını açıklamaktadır.
          </p>

          <h2 className="mt-4">Hangi Bilgileri Topluyoruz?</h2>
          <p>Web sitemizi ziyaret ettiğinizde, sizden topladığımız bilgiler şunlardır:</p>
          <ul className="list-group mb-4">
            <li className="list-group-item">
              Kişisel bilgiler (ad, e-posta adresi gibi) sadece kullanıcılar tarafından gönüllü olarak verildiğinde toplanır.
            </li>
            <li className="list-group-item">
              Tarayıcı bilgileri ve çerezler aracılığıyla otomatik olarak toplanan veriler.
            </li>
          </ul>

          <h2>Bilgilerin Kullanımı</h2>
          <p>Topladığımız bilgiler aşağıdaki amaçlar doğrultusunda kullanılmaktadır:</p>
          <ul className="list-group mb-4">
            <li className="list-group-item">Web sitemizi geliştirmek ve daha iyi hizmet sunmak.</li>
            <li className="list-group-item">Kullanıcı taleplerini ve geri bildirimlerini yanıtlamak.</li>
            <li className="list-group-item">
              Web sitesi güvenliğini ve kullanıcıların deneyimini iyileştirmek.
            </li>
          </ul>

          <h2>Bilgilerin Paylaşımı</h2>
          <p>
            Kişisel bilgileriniz, sizin izniniz olmadan üçüncü taraflarla paylaşılmamaktadır.
            Ancak yasalar gereği paylaşılması zorunlu durumlar oluşursa, ilgili makamlara bildirilir.
          </p>

          <h2>Çerezler</h2>
          <p>
            Web sitemiz, tarayıcınızda çerezler depolayabilir. Bu çerezler, sitemizde
            daha iyi bir kullanıcı deneyimi sunmamız için kullanılır. Çerezleri
            tarayıcı ayarlarınızdan kapatabilirsiniz.
          </p>

          <h2>Kullanıcı Hakları</h2>
          <p>
            Kişisel bilgilerinizin işlenmesi hakkında her zaman bilgi talep edebilir ve
            bu bilgilerin düzeltilmesini veya silinmesini talep edebilirsiniz.
          </p>

          <h2>İletişim</h2>
          <p>
            Gizlilik politikamızla ilgili sorularınız varsa, bizimle şu adresten iletişime geçebilirsiniz:
            <br />
            <strong>E-posta:</strong> <a href="mailto:admin@dynoblastizmir.com.tr">admin@dynoblastizmir.com.tr</a>
          </p>

          <p className="text-muted">Son Güncelleme: 21 Eylül 2024</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
