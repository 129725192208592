import React from 'react';
import {} from "react-icons/fa"
import toucanLogo from "../assets/web_logo_toucan.png";
import { FaLocationDot } from 'react-icons/fa6';

function Footer() {
  const currentYil = new Date().getFullYear();
  const address = "Atatürk+Mahallesi+Fatih+Sultan+Mehmet+Bulvarı+No:+50+Tire-İzmir"

  return (
    <footer className="bg-dark text-light py-4">
      <div className="container mb-5">
        <div className="row">
          <div className="col-md-4">
            <h5 className='yellow'>Hızlı Linkler</h5>
            <ul className="list-unstyled">
              <li><a href="#heroCarousel" className="text-light">Anasayfa</a></li>
              <li><a href="#about" className="text-light">Hakkımızda</a></li>
              <li><a href="#contact" className="text-light">İletişim</a></li>
              <li><a href="#yazilimlarimiz" className="text-light"><strong className='text-primary'>Yazılım</strong>larımız</a></li>
              <li><a href="#referanslarimiz" className="text-light"><strong className='text-warning'>Referans</strong>larımız</a></li>
              <li><a href="#referanslarimiz" className="text-light"><strong className='text-danger'>Kod</strong>luyoruz</a></li>
              <li><a href="/mdm" className="text-light">Mobil Cihaz Yönetimi</a></li>
            </ul>
          </div>
          <div className="col-md-4">
            <h5 className='yellow'>Bakanlık Linkleri</h5>
            <ul className="list-unstyled">
              <li><a href="https://www.kvkk.gov.tr/" target="_blank" rel="noopener noreferrer" className="text-light">KVKK</a></li>
              <li><a href="https://www.uab.gov.tr/" target="_blank" rel="noopener noreferrer" className="text-light">Ulaştırma Bakanlığı</a></li>
              <li><a href="https://www.cimer.gov.tr/" target="_blank" rel="noopener noreferrer" className="text-light">Cimer</a></li>
            </ul>
            <h5 className='yellow mt-2'>Linklerimiz</h5>
            <ul className="list-unstyled">
              <li><a href="/privacy-policy" className="text-light">Gizlilik Politikası</a></li>
              <li><a href="/terms-of-service" className="text-light">Hizmet Şartları</a></li>
              <li><a href="/kvkk" className="text-light">KVKK Sayfamız</a></li>
            </ul>
          </div>
          <div className="col-md-4">
            <h5 className='yellow'>Teira Bilişim</h5>
            <p>Adres: Atatürk Mah. Fatih Sultan Mehmet Bulvarı No: 50/6</p>
            <p>Tire - İzmir</p>
            <p>Telefon: +90 0546 541 62 82</p>
            <p>E-posta: dynoblastizmir@gmail.com</p>
            <a 
              href={`https://www.google.com/maps/search/?api=1&query=${address}`}
              target="_blank"
              rel="noopener noreferrer"
              className='btn btn-secondary'
            >
             <FaLocationDot /> Harita
            </a>
          </div>
        </div>
        <hr className="bg-light" />
        <div className="container copyright text-center">
        <p>
          <span>
            Created by{" "}
            <a
              href="https://www.linkedin.com/in/szaferulgur/"
              target="_blank"
              rel="noreferrer"
            >
              <span className="fenerbahce">SZÜ</span>
            </a>
              
            <img
              className="cursor-pointer"
              src={toucanLogo}
              alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer"
              style={{ width: "25px" }}
              onClick={() =>
                window.open("https://github.com/SZaferUlgur", "_blank")
              }
            />
              
            {currentYil}
          </span>
        </p>
      </div>
      </div>
    </footer>
  );
}

export default Footer;
