import React from 'react';
import emailjs from 'emailjs-com';

function Contact() {

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID')
      .then((result) => {
          console.log(result.text);
          alert('Mesajınız başarıyla gönderildi!');
      }, (error) => {
          console.log(error.text);
          alert('Mesaj gönderilirken bir hata oluştu. Lütfen tekrar deneyin.');
      });
      
      e.target.reset();
  }

  return (
    <section className="contact-section py-5 bg-light" id='contact'>
      <div className="container">
        <h2 className="text-center text-primary mb-5">İletişime Geçin</h2>
        <div className="row">
          <div className="col-md-6">
            <form onSubmit={sendEmail}>
              <div className="mb-3 contact-form">
                <label htmlFor="name" className="form-label">Adınız</label>
                <input type="text" className="form-control" id="name" name="name" required />
              </div>
              <div className="mb-3 contact-form">
                <label htmlFor="email" className="form-label">E-posta</label>
                <input type="email" className="form-control" id="email" name="email" required />
              </div>
              <div className="mb-3 contact-form">
                <label htmlFor="message" className="form-label">Mesajınız</label>
                <textarea className="form-control" id="message" name="message" rows="5" required></textarea>
              </div>
              <button type="submit" className="btn btn-primary mb-3 w-100">Gönder</button>
            </form>
          </div>
          <div className="col-md-6">
            <h5 className='orange'>Teira Bilişim</h5>
            <p>Adres: Atatürk Mah. Fatih Sultan Mehmet Bulvarı No: 50/6</p>
            <p>Tire - İzmir</p>
            <p>Telefon: +90 0546 541 62 82</p>
            <p>E-posta: serkan@dynoblastizmir.com.tr</p>
            <p>Çalışma Saatleri: Pazartesi - Cuma 09:00 - 18:00</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
