import React, { useState } from "react";
import { Link } from "react-router-dom";
import dynoLogo from "../assets/dynablast_logo_small_yesil.png";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const pathName = window.location.pathname;

  const rotaKontrol = () => {
    if (pathName !== "/") {
      navigate("/");
    }
  };


  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-light fixed-top"
      id="home"
    >
      <div className="container-fluid d-flex justify-content-between align-items-center">
        <Link className="navbar-brand d-flex align-items-center" to="/">
          <div className="navbar-image">
            <img
              src={dynoLogo}
              alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer"
              className="img-fluid"
            />
          </div>
        </Link>

        <button
          className={`navbar-toggler ${sideBarOpen ? "" : "collapsed"}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded={sideBarOpen}
          aria-label="Toggle navigation"
          onClick={() => setSideBarOpen(!sideBarOpen)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={`container collapse navbar-collapse ${
            sideBarOpen ? "show" : ""
          }`}
          id="navbarNav"
        >
          <ul className="navbar-nav ml-auto">
            <li className="nav-item" onClick={() => setSideBarOpen(false)}>
              <a
                href="#heroCarousel"
                className="nav-link"
                onClick={() => rotaKontrol()}
              >
                Anasayfa
              </a>
            </li>
            <li className="nav-item" onClick={() => setSideBarOpen(false)}>
              <a
                href="#about"
                className="nav-link"
                onClick={() => rotaKontrol()}
              >
                Hakkımızda
              </a>
            </li>
            <li className="nav-item" onClick={() => setSideBarOpen(false)}>
              <a
                href="#yazilimlarimiz"
                className="nav-link"
                onClick={() => rotaKontrol()}
              >
                <strong className="text-primary">Yazılım</strong>larımız
              </a>
            </li>
            <li className="nav-item" onClick={() => setSideBarOpen(false)}>
              <a
                href="#referanslarimiz"
                className="nav-link"
                onClick={() => rotaKontrol()}
              >
                <strong className="text-warning">Referans</strong>larımız
              </a>
            </li>
            <li className="nav-item" onClick={() => setSideBarOpen(false)}>
              <a
                href="#nelerkodluyoruz"
                className="nav-link"
                onClick={() => rotaKontrol()}
              >
                <strong className="text-danger">Kod</strong>luyoruz
              </a>
            </li>
            <li className="nav-item" onClick={() => setSideBarOpen(false)}>
              <a
                href="/mdm"
                className="nav-link"
                onClick={() => rotaKontrol()}
              >
                MDM
              </a>
            </li>
            <li className="nav-item" onClick={() => setSideBarOpen(false)}>
              <a
                href="#contact"
                className="nav-link"
                onClick={() => rotaKontrol()}
              >
                İletişim
              </a>
            </li>
            {/* <li className="nav-item px-4" onClick={() => setSideBarOpen(false)}>
              {!token ? (
                <button
                  className="btn btn-table btn-primary mt-2"
                  onClick={() => navigate("/login")}
                >
                  <sup>
                    <FaSignInAlt /> Giriş
                  </sup>
                </button>
              ) : (
                <button
                  className="btn btn-table btn-danger mt-2"
                  onClick={() => durumaGore()}
                >
                  <sup>
                    {pathName === "/dashboard" ? (
                      <>
                        <FaSignOutAlt /> {tokenTusAdi}
                      </>
                    ) : (
                      <>
                        <MdDashboard /> {tokenTusAdi}
                      </>
                    )}
                  </sup>
                </button>
              )}
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
