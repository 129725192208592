import React from "react";
import barkodCihaz from "../assets/mdm/mobil_cihaz.jpg";
import { useNavigate } from "react-router-dom";

const MdmTanitim = () => {
    const navigate = useNavigate();
  return (
    <section className="software-section py-5 bg-white" id="mdmpage">
      <div className="container-mdm text-center cursor-pointer" onClick={() => navigate("/mdm")}>
        <h1 className="font-24"><b>Mobil Cihaz Yönetimi</b></h1>
        <div className="image-container">
          <img
            src={barkodCihaz}
            alt="PATBİS Uyumlu Cihaz, PATBİS Uyumlu Depo Yönetim Sistemi, PATBİS Uyumlu Barkod Okuyucu"
            className="img-fluid main-image"
          />
        </div>
        <h1 className="font-16"><i><u>Detaylar İçin Tıklayınız</u></i></h1>
      </div>
    </section>
  );
};

export default MdmTanitim;
