import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import About from "./components/About";
import Contact from "./components/Contact";
import Yazilimlarimiz from "./components/Yazilimlarimiz";
import Referanslarimiz from "./components/Referanslarimiz";
import NelerKodluyoruz from "./components/NelerKodluyoruz";
import DynoblastDetail from "./components/DynoblastDetail";
import MahsulDetail from "./components/MahsulDetail";
import NakliyeDetail from "./components/NakliyeDetail";
import FAQ from "./components/FAQ";
import OurTeam from "./components/OurTeam";
import FiyatlarPage from "./components/FiyatlarPage";
import NotFound from "./components/NotFound"; // NotFound bileşenini ekleyin
import Meta from "./components/Meta";
import SiteMapPage from "./components/SiteMapPage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import MadenReferans from "./components/MadenReferans";
import KVKKPage from "./components/KVKKPage";
import WhatsAppButton from "./components/WhatsappButton";
import MdmPage from "./components/MDMPage";
import MdmTanitim from "./components/MdmTanitim";

function App() {
  const location = useLocation();

  const renderComponent = () => {
    switch (location.pathname) {
      case "/dynoblastdetail":
        return <DynoblastDetail />;
      case "/mahsuldetail":
        return <MahsulDetail />;
      case "/nakliyedetail":
        return <NakliyeDetail />;
      case "/madenref":
        return <MadenReferans />;
      case "/sitemap.xml":
        return <SiteMapPage />;
      case "/privacy-policy":
        return <PrivacyPolicy />;
      case "/terms-of-service":
        return <TermsOfService />;
      case "/kvkk":
        return <KVKKPage />;
        case "/mdm":
          return <MdmPage />;
      default:
        return <NotFound />;
    }
  };

  const showWhatsAppButton = [
    "/",
    "/dynoblastdetail",
    "/mahsuldetail",
    "/nakliyedetail",
    "/madenref",
    "/privacy-policy",
    "/terms-of-service",
    "/kvkk",
    "/mdm",
  ].includes(location.pathname);

  return (
    <div className="App">
      {location.pathname !== "/sitemap.xml" && <Navbar />}
      <Meta />
      {location.pathname === "/" ? (
        <>
          <Hero />
          <About />
          <Yazilimlarimiz />
          <FiyatlarPage />
          <MdmTanitim />
          <Referanslarimiz />
          <NelerKodluyoruz />
          <FAQ />
          <OurTeam />
          <Contact />
        </>
      ) : (
        renderComponent()
      )}
      {showWhatsAppButton && <WhatsAppButton />}
      {location.pathname !== "/sitemap.xml" && <Footer />}
    </div>
  );
}

export default App;
