import React from 'react';
import mobilGuvenlik from '../assets/mdm/cihaz_guvenlik.jpg';
import mdmInternet from '../assets/mdm/mdm_internet.jpg';
import mdmKontrol from '../assets/mdm/barkod_baski.jpg';
import mdmUygulama from '../assets/mdm/stok_sayim.jpg';
import { FaWhatsapp } from 'react-icons/fa';

const formatPhoneNumber = (phone) => {
    let formatted = phone.replace(/\D/g, "");
    if (formatted.startsWith("0")) {
      formatted = formatted.substring(1);
    }
    if (!formatted.startsWith("90")) {
      formatted = "90" + formatted;
    }
    return formatted;
  };

const MdmPage = () => {
    const phoneNumber = "+905465416282";
    const whatsappLink = `https://wa.me/${formatPhoneNumber(phoneNumber)}`;

  return (
    <div className="container my-5">
      <div className="row mb-4">
        <div className="col-md-6 d-flex align-items-center">
          <div>
            <h1 className="display-5 fw-bold">Mobil Cihaz Yönetimi</h1>
            <p className="lead">
              Mobil Cihaz Yönetimi çözümleri, şirketinizde kullanılan mobil cihazların yönetimini
              ve güvenliğini sağlar. Cihazları uzaktan yönetebilir, uygulama
              yükleyebilir ve politikalar belirleyebilirsiniz.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <img
            src={mobilGuvenlik}
            alt="PATBİS Uyumlu Cihaz, PATBİS Uyumlu Depo Yönetim Sistemi, PATBİS Uyumlu Barkod Okuyucu"
            className="img-fluid"
          />
        </div>
      </div>

      {/* Section 1 */}
      <div className="row mb-5">
        <div className="col-md-6">
          <img
            src={mdmInternet}
            alt="PATBİS Uyumlu Cihaz, PATBİS Uyumlu Depo Yönetim Sistemi, PATBİS Uyumlu Barkod Okuyucu, MDM Yönetim Sistemi"
            className="img-fluid"
          />
        </div>
        <div className="col-md-6 d-flex align-items-center">
          <div>
            <h2 className="fw-bold">Mobil Cihaz Güvenliği</h2>
            <p>
              Mobil cihazlar, şirket verileri için ciddi bir tehdit oluşturabilir.
              Mobil Cihaz Yönetimi sayesinde cihazlarınızı uzaktan yönetebilir, verileri şifreleyebilir ve güvenlik açıklarını kapatabilirsiniz.
            </p>
          </div>
        </div>
      </div>

      {/* Section 2 */}
      <div className="row mb-5">
        <div className="col-md-6 d-flex align-items-center">
          <div>
            <h2 className="fw-bold">Kolay Yönetim ve İzleme</h2>
            <p>
              MDM çözümleri ile tüm cihazlarınızı tek bir merkezi platformdan
              yönetebilir, uygulama yükleyebilir, cihaz izleme yapabilir ve
              uzaktan kontroller sağlayabilirsiniz.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <img
            src={mdmKontrol}
            alt="PATBİS Uyumlu Cihaz, PATBİS Uyumlu Depo Yönetim Sistemi, PATBİS Uyumlu Barkod Okuyucu, MDM Yönetim Sistemi"
            className="img-fluid"
          />
        </div>
      </div>

      {/* Section 3 */}
      <div className="row mb-5">
        <div className="col-md-6">
          <img
            src={mdmUygulama}
            alt="PATBİS Uyumlu Cihaz, PATBİS Uyumlu Depo Yönetim Sistemi, PATBİS Uyumlu Barkod Okuyucu, MDM Yönetim Sistemi"
            className="img-fluid"
          />
        </div>
        <div className="col-md-6 d-flex align-items-center">
          <div>
            <h2 className="fw-bold">Gelişmiş Analitik ve Raporlama</h2>
            <p>
              Cihaz kullanımı ve güvenliği hakkında detaylı raporlar oluşturabilir,
              hangi cihazların uyumlu olduğunu kontrol edebilirsiniz.
            </p>
          </div>
        </div>
      </div>

      {/* Call to Action */}
      <div className="row text-center mt-5">
        <div className="col">
          <h3 className="fw-bold">MDM Çözümleri ile Şirketinizin Güvenliğini ve Cihazlarınızın Kullanım Kolaylığını Artırın!</h3>
          <p>Size uygun çözümleri öğrenmek için bizimle iletişime geçin.</p>
          <a className='btn btn-success' href={whatsappLink} target="_blank" rel="noopener noreferrer">
          <FaWhatsapp size="24px" /> Daha Fazla Bilgi Alın
      </a>
        </div>
      </div>
    </div>
  );
};

export default MdmPage;
