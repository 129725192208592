import React, { useEffect, useState } from "react";

const baseUrl = "https://www.dynoblastizmir.com.tr"; // Bu URL'i kendi domain adınıza göre güncelleyin

const pages = [
  { loc: `${baseUrl}/`, priority: "1.0", lastmod: new Date().toISOString() },
  {
    loc: `${baseUrl}/about`,
    priority: "0.8",
    lastmod: new Date().toISOString(),
  },
  {
    loc: `${baseUrl}/yazilimlarimiz`,
    priority: "0.8",
    lastmod: new Date().toISOString(),
  },
  {
    loc: `${baseUrl}/dynoblastdetail`,
    priority: "0.7",
    lastmod: new Date().toISOString(),
  },
  {
    loc: `${baseUrl}/mahsuldetail`,
    priority: "0.7",
    lastmod: new Date().toISOString(),
  },
  {
    loc: `${baseUrl}/nakliyedetail`,
    priority: "0.7",
    lastmod: new Date().toISOString(),
  },
  {
    loc: `${baseUrl}/testimonial`,
    priority: "0.6",
    lastmod: new Date().toISOString(),
  },
  {
    loc: `${baseUrl}/contact`,
    priority: "0.6",
    lastmod: new Date().toISOString(),
  },
  {
    loc: `${baseUrl}/sitemap`,
    priority: "0.5",
    lastmod: new Date().toISOString(),
  },
  {
    loc: `${baseUrl}/privacy-policy`,
    priority: "0.6",
    lastmod: new Date().toISOString(),
  }, // Privacy Policy eklendi
  {
    loc: `${baseUrl}/terms-of-service`,
    priority: "0.6",
    lastmod: new Date().toISOString(),
  }, // Terms of Service eklendi
];

const SiteMapPage = () => {
  const [xmlContent, setXmlContent] = useState("");

  const generateSitemapXml = () => {
    const xmlHeader = '<?xml version="1.0" encoding="UTF-8"?>\n';
    const urlsetOpen =
      '<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n';
    const urlsetClose = "</urlset>";

    // Her sayfa için XML yapısını oluştur
    const urlElements = pages
      .map((page) => {
        return (
          `  <url>\n` +
          `    <loc>${page.loc}</loc>\n` +
          `    <lastmod>${page.lastmod}</lastmod>\n` +
          `    <priority>${page.priority}</priority>\n` +
          `  </url>\n`
        );
      })
      .join("");

    // Tüm XML dosyasını birleştir
    return xmlHeader + urlsetOpen + urlElements + urlsetClose;
  };

  useEffect(() => {
    const xml = generateSitemapXml();
    setXmlContent(xml);
  }, []);

  return <pre style={{ whiteSpace: "pre-wrap" }}>{xmlContent}</pre>;
};

export default SiteMapPage;
